/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';

export default function Armchairs(props) {
  const { nodes, materials } = useGLTF('/3d/Armchairs/Armchairs.gltf');
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.polySurface5748.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.polySurface5794.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload('/3d/Armchairs/Armchairs.gltf');
