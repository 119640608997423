/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Seashell_3.gltf
Created: Wed May 31 12:23:00 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Seashell_3/Seashell_3.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Seashell_3/Seashell_3_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Seashell_3_mat.lightMap = lightMap;
  materials.Seashell_3_mat.lightMapIntensity = 0;
  materials.Seashell_3_mat.lightMap.flipY = false;
  materials.Seashell_3_mat.envMap = scene.background;
  materials.Seashell_3_mat.envMapIntensity = 2;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Seashell_3.geometry}
        material={materials.Seashell_3_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Seashell_3/Seashell_3.gltf',
  '/optimizedAssets/Seashell_3/Seashell_3_Lightmap.jpg',
]);
