import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function PassionsAndPastimesScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20 sec) Passion & Pastimes Animation v3.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="Passions & Pastimes"
    //   description="Create a record of your favourite pastimes. Record the results of games you played, the fish you caught, the runs you made, rugby matches you watched, dinner parties you attended, the sailing regattas you won or maybe the meals you enjoyed or the wines you tasted. Using your smartphone, tablet or computer you can upload images, videos and comments directly to your passions & pastimes diary."
    //   slides={[{ photoUrl:'/img/galleries/passions-and-pastimes/image0.jpeg' }]}
    // />
  );
}
