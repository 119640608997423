/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Walls.gltf
Created: Sun May 28 23:04:19 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('optimizedAssets/Walls/Walls.gltf');
  const lightMap = useTexture(
    '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Walls_mat.lightMap = lightMap;
  materials.Walls_mat.lightMapIntensity = 1;
  materials.Walls_mat.lightMap.flipY = false;
  materials.Walls_mat.envMap = scene.background;
  materials.Walls_mat.envMapIntensity = 2;
  materials.Walls_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Walls.geometry}
        material={materials.Walls_mat}
        position={[4.943, 1.449, 0.084]}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Walls/Walls.gltf',
  '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg',
]);
