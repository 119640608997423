import { useTexture } from '@react-three/drei';
import * as React from 'react';

export default function WalkIndicator() {
  const textures = useTexture({
    color: 'walk-indicator.png',
    alpha: 'walk-indicator-alpha.png',
  });

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]}>
      {/* <sphereGeometry args={[1, 32, 32]} /> */}
      <planeGeometry args={[0.5, 0.5]} />
      <meshPhongMaterial
        transparent
        map={textures.color}
        emissive={0xffffff}
        // alphaMap={textures.alpha}
      />
      {/* <meshBasicMaterial color={0xffff00} side={THREE.DoubleSide} /> */}
    </mesh>
  );
}
