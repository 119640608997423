/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';

export default function Desk(props) {
  const { nodes, materials } = useGLTF('/3d/Desk/Desk.gltf');
  // materials.Desk.displacementMap = useTexture('3d/Desk/Textures/Desk_LP_Desk_Height.png');
  // materials.Desk.displacementScale = 30;

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.pCube258_1.geometry}
        material={materials.Desk}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.pCube258_2.geometry}
        material={materials.DeskFrame}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.pCube258_3.geometry}
        material={materials.DeskLamp}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.pCube258_4.geometry}
        material={materials.Keyboard}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.pCube258_5.geometry}
        material={materials.Mac}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.pCube258_6.geometry}
        material={materials.Mouse}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload('/3d/Desk/Desk.gltf');
