import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function VideoWelcomeScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20-sec) Welcome Note Animation v1.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   slides={[{ videoUrl: '/img/galleries/welcome.mp4' }]}
    // />
  );
}
