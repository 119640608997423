/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Safe.gltf
Created: Wed May 31 20:13:21 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Safe/Safe.gltf');
  const lightMap = useTexture('/optimizedAssets/Safe/Safe_Lightmap.jpg');
  const { scene } = useThree();

  materials.Safe_mat.lightMap = lightMap;
  materials.Safe_mat.lightMapIntensity = 4;
  materials.Safe_mat.lightMap.flipY = false;
  materials.Safe_mat.envMap = scene.background;
  materials.Safe_mat.envMapIntensity = 5;

  return (
    <group dispose={null}>
      <Select enabled={props.hoverTarget === 'safe'}>
        <group {...props} scale={100} userData={{ hoverTarget: 'safe' }}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Safe.geometry}
            material={materials.Safe_mat}
          />
        </group>
      </Select>
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Safe/Safe.gltf',
  '/optimizedAssets/Safe/Safe_Lightmap.jpg',
]);
