import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function PhotosSerengetiScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20-sec) Pictures Animation v1.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="Serengeti"
    //   description="So you have clicked on my wonderful Alfred Milne 1970 teak and
    //   mahogany ketch. Currently under water in English Harbour, Antigua.
    //   The best fun times ever… undoubtedly cost me my second marriage.
    //   Enjoy the following pics and stories from the many guests on board."
    //   slides={[
    //     { photoUrl: '/img/galleries/serengeti/image0.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image1.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image2.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image3.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image4.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image5.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image6.jpg' },
    //     { photoUrl: '/img/galleries/serengeti/image7.jpg' },
    //   ]}
    // />
  );
}
