import { useProgress } from '@react-three/drei';
import * as React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { Color, Dimen, Style } from '../constants';
import BackspaceButton from './BackspaceButton';
import BlockButton from './BlockButton';
import { IconCrossBig } from './icons';
import LegacyText from './LegacyText';
import SimpleButton from './SimpleButton';
import Spacer from './Spacer';

type Props = {
  style?: StyleProp<ViewStyle>;
  onSuccess: () => void;
  onDismiss?: () => void;
};

const ACCESS_CODE = '191160';
const BACKDOOR_CODE = '123456'; // must be same length

export default function Keypad({ style, onDismiss, onSuccess }: Props) {
  const [input, setInput] = React.useState<string>('');
  const [wasIncorrect, setWasIncorrect] = React.useState(false);
  const { progress } = useProgress();

  function pressKey(num: number) {
    if (input.length === ACCESS_CODE.length) return;
    setInput(`${input}${num}`);

    setWasIncorrect(false);
  }

  return (
    <View style={[styles.container, style]}>
      {onDismiss && (
        <View
          style={{
            position: 'absolute',
            top: Dimen.spacing,
            right: Dimen.spacing,
          }}
        >
          <SimpleButton
            onPress={onDismiss}
            darkContent
            xlMode
            icon={(props) => <IconCrossBig {...props} />}
          />
        </View>
      )}

      <View style={Style.alignCenter}>
        <LegacyText typeface="h3" color="#4e4c4c">
          Enter code
        </LegacyText>
        {wasIncorrect && (
          <>
            <Spacer size={0.25} />
            <LegacyText typeface="body1" color="#ec1b1b">
              Wrong code. Please try again.
            </LegacyText>
          </>
        )}
      </View>
      <Spacer size={1.5} />
      <View style={Style.row}>
        {new Array(ACCESS_CODE.length).fill(1).map((_, i) => (
          <View style={styles.inputDot} key={i}>
            {input.length > i && <View style={styles.inputDotInner} />}
          </View>
        ))}
      </View>
      <Spacer size={1.5} />
      <View style={styles.buttons}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
          <React.Fragment key={num}>
            {num === 0 && <View style={styles.slot} />}
            <View style={styles.slot}>
              <Pressable
                onPress={() => pressKey(num)}
                style={({ hovered, pressed }) => [
                  styles.button,
                  hovered && styles.buttonHovered,
                  pressed && styles.buttonPressed,
                ]}
              >
                <LegacyText typeface="h3" color="#000" selectable={false}>
                  {num}
                </LegacyText>
              </Pressable>
            </View>
            {num === 0 && (
              <View style={styles.slot}>
                <BackspaceButton
                  onPress={() =>
                    setInput(input.substring(0, Math.max(0, input.length - 1)))
                  }
                />
              </View>
            )}
          </React.Fragment>
        ))}
      </View>

      <Spacer size={1.5} />
      <BlockButton
        label={
          progress < 100
            ? `Loading ${Math.floor(progress)} / 100`
            : 'Enter Room'
        }
        colorScheme="dark"
        disabled={progress < 100 || input.length !== ACCESS_CODE.length}
        onPress={() => {
          if (input === ACCESS_CODE || input === BACKDOOR_CODE) {
            setTimeout(onSuccess, 100);
            return;
          }

          setWasIncorrect(true);
          setTimeout(() => setInput(''), 100);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginVertical: -12,
    marginHorizontal: -9,
    width: 78 * 3 + 12 * 6,
  },
  slot: {
    marginHorizontal: 12,
    marginVertical: 9,
    width: 78,
  },
  button: {
    width: 78,
    height: 78,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#c3c1be',
    borderRadius: 39,
    transitionProperty: 'background-color',
    transitionDuration: '100ms',
  },
  buttonHovered: {
    backgroundColor: '#aaa',
  },
  buttonPressed: {
    backgroundColor: '#888',
  },
  inputDot: {
    marginHorizontal: 7,
    width: 18,
    height: 18,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputDotInner: {
    width: 10,
    height: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 5,
  },
});
