import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function TravelTrackerScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20-sec) Travel Tracker Animation v3.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="Travel Tracker"
    //   description="Record your trips by dropping a pin on the legacy room wall map. Include photos, videos and comments from your travels. Build a record over time of your exotic foreign holidays or your adventures nearer home. Use the Travel Tracker to share your amazing memories and stories from wide and far."
    //   slides={[{ photoUrl:'/img/galleries/travel-tracker/image0.jpeg' }]}
    // />
  );
}
