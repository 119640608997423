/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/updatedAssets/Frames/Frames.gltf
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/updatedAssets/Frames/Frames.gltf')
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.FRAME_LEG.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.FRAME_LEG1.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.FRAME_LEG2.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17315.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17316.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17318.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17319.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17303.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17304.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17309.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17310.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17312.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17313.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17300.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17301.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17297.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17298.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17294.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17295.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17321.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17322.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17291.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17292.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17306.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17307.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17324.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17325.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17289.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17290.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17287.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17288.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17285.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17286.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17283.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17284.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17281.geometry} material={materials.FRAMES} />
        <mesh castShadow receiveShadow geometry={nodes.polySurface17282.geometry} material={materials.FRAMES} />
      </group>
    </group>
  )
}

useGLTF.preload('/updatedAssets/Frames/Frames.gltf')
