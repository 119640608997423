/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Shell_bottoms.gltf
Created: Wed May 31 09:24:24 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Shell_bottoms/Shell_bottoms.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Shell_bottoms/Shell_bottoms_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Shell_bottoms_mat.lightMap = lightMap;
  materials.Shell_bottoms_mat.lightMapIntensity = 2.5;
  materials.Shell_bottoms_mat.lightMap.flipY = false;
  materials.Shell_bottoms_mat.envMap = scene.background;
  materials.Shell_bottoms_mat.envMapIntensity = 3;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shell_bottoms.geometry}
        material={materials.Shell_bottoms_mat}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Shell_bottoms/Shell_bottoms.gltf',
  '/optimizedAssets/Shell_bottoms/Shell_bottoms_Lightmap.jpg',
]);
