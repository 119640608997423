/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Bookshelves.gltf
Created: Wed May 31 14:00:15 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Bookshelves/Bookshelves.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Bookshelves/Bookshelves_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Bookshelves_mat.lightMap = lightMap;
  materials.Bookshelves_mat.lightMapIntensity = 5;
  materials.Bookshelves_mat.lightMap.flipY = false;
  materials.Bookshelves_mat.envMap = scene.background;
  materials.Bookshelves_mat.envMapIntensity = 5;
  materials.Bookshelves_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Bookshelves.geometry}
        material={materials.Bookshelves_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Bookshelves/Bookshelves.gltf',
  '/optimizedAssets/Bookshelves/Bookshelves_Lightmap.jpg',
]);
