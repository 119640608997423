import {
  BakeShadows,
  OrbitControls,
  PerspectiveCamera,
  useCubeTexture,
  useCursor,
} from '@react-three/drei';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import {
  EffectComposer,
  Outline,
  Selection,
} from '@react-three/postprocessing';
import * as React from 'react';
import * as THREE from 'three';

import { RoomD, WalkIndicator } from './3d';
import { INITIAL_CAM_POS, INITIAL_TARGET } from './Root';

// https://github.com/pmndrs/react-three-fiber/issues/19
const walkAreaPoints = [
  // Start in left bookshelf corner just in front of ladder
  [-3.1, 2.35],
  // Reaching right bookshelf corner
  [-3.1, -2.1],
  [-2.5, -2.1],
  [-2.5, -1.7],
  [-0.65, -1.7],
  [-0.65, -1],
  [2.5, -1],
  [2.5, -1.6],
  // Reaching Back right corner
  [4.1, -1.6],
  // Reaching back left corner
  [4.1, 1.4],
  [2.95, 1.4],
  [2.3, 0.85],
  [1.55, 1.5],
  [-2.2, 1.8],
  [-2.3, 2.35],
];
const shape = new THREE.Shape();
shape.moveTo(...walkAreaPoints[0]);
for (let i = 1; i < walkAreaPoints.length; i++) {
  shape.lineTo(...walkAreaPoints[i]);
}

// No need for this, just look at pointerDown/up pageX/Y instead
/* 
// https://discourse.threejs.org/t/check-points-is-inside-a-polygon-doesnt-work-correct/7291/2
function isInWalkArea(point): bool {
  const vs = walkAreaPoints.map((p) => ({ x: p[0], y: p[1] }));
  const { x, y } = point;

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
      yi = vs[i].y;
    var xj = vs[j].x,
      yj = vs[j].y;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

const clickPointSize = 0.2;
const walkClickPoints = [];
for (let x = 0; x < 3; x += clickPointSize) {
  for (let y = 0; y < 3; y += clickPointSize) {
    const point = { x, y };
    if (isInWalkArea(point)) {
      walkClickPoints.push(point);
    }
  }
} */

const raycaster = new THREE.Raycaster();

const environmentMap = new THREE.CubeTextureLoader().load([
  '/envmap/px.jpg',
  '/envmap/nx.jpg',
  '/envmap/py.jpg',
  '/envmap/ny.jpg',
  '/envmap/pz.jpg',
  '/envmap/nz.jpg',
]);

export default function Scene({
  currentAnimVector,
  currentAnimStartDistance,
  desiredTargetPoint,
  moveTo,
  orbitRef,
  setCurrentAnimVector,
  setCurrentAnimStartDistance,
  showScreen,
}) {
  const [walkIndicatorPoint, setWalkIndicatorPoint] = React.useState(null);
  const [hoverTarget, setHoverTarget] = React.useState<
    null | 'tv' | 'computer'
  >(null);
  useCursor(!!hoverTarget, 'pointer', 'grab');

  const cameraRef = React.useRef();

  const [lastPointerDownPagePos, setLastPointerDownPagePos] = React.useState<
    null | [number, number]
  >(null);

  // Show outline over what's in center of screen
  const { gl, scene } = useThree();

  const envMap = useCubeTexture(
    ['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg'],
    { path: '/envmap/' }
  );
  const [cubeMap] = useLoader(
    THREE.CubeTextureLoader,
    [['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg']],
    (loader) => {
      loader.setPath('/envmap/');
    }
  );

  const cubeMapRef = React.useRef();

  React.useEffect(() => {
    const gen = new THREE.PMREMGenerator(gl);
    gen.compileEquirectangularShader();
    const hdrCubeRenderTarget = gen.fromCubemap(cubeMap);
    cubeMap.dispose();
    gen.dispose();
    scene.background = hdrCubeRenderTarget.texture;
    scene.environment = hdrCubeRenderTarget.texture;
    cubeMapRef.current = hdrCubeRenderTarget.texture;
    return () => {
      scene.environment = null;
      scene.background = null;
    };
  }, [cubeMap]);

  useFrame(() => {
    // Don't bother running if it's not a touch screen (they can just use mouse)
    if (!('ontouchstart' in window)) {
      return;
    }

    raycaster.setFromCamera(
      { x: 0, y: 0 }, // normalized (-1 to +1)
      cameraRef.current
    );

    const intersects = raycaster.intersectObjects(scene.children);
    for (const intersect of intersects) {
      const intersectingTarget = intersect.object?.userData?.hoverTarget;
      if (intersectingTarget) {
        if (intersectingTarget !== hoverTarget) {
          setHoverTarget(intersectingTarget);
        }
        return;
      }
    }
    if (hoverTarget) {
      setHoverTarget(null);
    }
  });

  // Walk to/from trigger object animation
  useFrame((state, delta) => {
    if (!currentAnimVector) return;

    const dist = orbitRef.current.target.distanceTo(desiredTargetPoint);
    if (dist < 0.1) {
      setCurrentAnimVector(null);
      setCurrentAnimStartDistance(null);
      return;
    }

    // How far from either start or end?
    const betweenDist = Math.min(
      dist,
      Math.max(0, (currentAnimStartDistance ?? 0) - dist)
    );

    // Meters per second
    const moveSpeed = 1.5 + betweenDist * 2;
    const moveAmount = Math.min(
      moveSpeed * delta,
      // Make sure not to overshoot
      dist
    );

    cameraRef.current.position.x += currentAnimVector.x * moveAmount;
    cameraRef.current.position.y += currentAnimVector.y * moveAmount;
    cameraRef.current.position.z += currentAnimVector.z * moveAmount;
    orbitRef.current.target.x += currentAnimVector.x * moveAmount;
    orbitRef.current.target.y += currentAnimVector.y * moveAmount;
    orbitRef.current.target.z += currentAnimVector.z * moveAmount;
  });

  return (
    <>
      {/* Room general lighting */}
      <ambientLight intensity={0.2} />
      <Selection>
        <EffectComposer multisampling={8} autoClear={false}>
          <Outline
            blur
            visibleEdgeColor="white"
            edgeStrength={100}
            width={2000}
          />
        </EffectComposer>
        <RoomD
          // Convert from 1unit=1cm to 1unit=1m (SI units)
          // https://github.com/mrdoob/three.js/issues/6259
          scale={[0.01, 0.01, 0.01]}
          // Get back closer to origin
          position={[
            -3, // Left
            0, // Down
            0, // Backwards
          ]}
          cubeMap={cubeMap}
          hoverTarget={hoverTarget}
          setHoverTarget={setHoverTarget}
          moveTo={moveTo}
          setWalkIndicatorPoint={setWalkIndicatorPoint}
          showScreen={showScreen}
        />
      </Selection>

      <mesh
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0.04, 0]}
        onPointerOut={() => setWalkIndicatorPoint(null)}
        onPointerMove={(ev) => {
          setWalkIndicatorPoint(ev.point);
        }}
        onPointerDown={(ev) => {
          setLastPointerDownPagePos([ev.pageX, ev.pageY]);
        }}
        onPointerUp={(ev) => {
          if (!lastPointerDownPagePos) return;
          const [lastX, lastY] = lastPointerDownPagePos;
          if (
            Math.abs(lastX - ev.pageX) < 10 &&
            Math.abs(lastY - ev.pageY) < 10
          ) {
            moveTo(new THREE.Vector3(ev.point.x, 1.75, ev.point.z));
          }
          setLastPointerDownPagePos(null);
        }}
      >
        <shapeGeometry args={[shape]} />
        <meshBasicMaterial
          color="orange"
          side={THREE.DoubleSide}
          transparent
          // Set to 1 to see walkable area
          opacity={0}
        />
      </mesh>

      {/* {walkClickPoints.map((point) => (
        <mesh
          rotation={[Math.PI / 2, 0, 0]}
          position={[point.x, 0.045, point.y]}
        >
          <planeGeometry args={[clickPointSize, clickPointSize]} />
          <meshBasicMaterial color="green" side={THREE.DoubleSide} />
        </mesh>
      ))} */}

      {walkIndicatorPoint && (
        <group position={[walkIndicatorPoint.x, 0.08, walkIndicatorPoint.z]}>
          <WalkIndicator />
        </group>
      )}
      {/* <spotLight
        position={[-0.2, 1.6, 3.9]}
        intensity={0.3}
        penumbra={0.2}
        castShadow
        shadow-mapSize={[2048, 2048]}
        shadow-radius={10}
        shadow-bias={0.005}
      /> */}
      {/*<directionalLight
        color={0xffffff}
        intensity={0.5}
        position={[-3.2, 1.9, 0]}
        castShadow
        shadow-mapSize={[2048, 2048]}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        shadow-camera-near={0.5}
        shadow-camera-far={500}
        shadow-radius={20}
        shadow-bias={0.0005}
    />*/}

      {/*<spotLight
        position={[-0.9, 2.25, -2.8]}
        intensity={0.1}
        decay={2}
        penumbra={0.1}
        castShadow
        shadow-mapSize={[1024, 1024]}
        shadow-radius={20}
        shadow-bias={-0.000001}
      />
      <spotLight
        position={[1.0, 2.25, -2.8]}
        intensity={0.1}
        decay={2}
        penumbra={0.1}
        castShadow
        shadow-mapSize={[1024, 1024]}
        shadow-radius={20}
        shadow-bias={-0.000001}
      />
      <spotLight
        position={[2.9, 2.25, -2.8]}
        intensity={0.1}
        decay={2}
        penumbra={0.1}
        castShadow
        shadow-mapSize={[1024, 1024]}
        shadow-radius={20}
        shadow-bias={-0.000001}
    />*/}
      {/* <Desk position={[3, 0, 2]} rotation={[0, (Math.PI / 4) * 6, 0]} />  */}

      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        fov={55}
        position={INITIAL_CAM_POS}
      />
      <OrbitControls
        ref={orbitRef}
        enablePan={false}
        enableZoom={false}
        target={INITIAL_TARGET}
        reverseOrbit
        enableDamping
        dampingFactor={0.125}
        rotateSpeed={0.25}
        minZoom={0.1}
        maxZoom={0.1}
      />
    </>
  );
}
