/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Desk_Chair.gltf
Created: Wed May 31 01:45:49 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Desk_Chair/Desk_Chair.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Desk_Chair/Desk_Chair_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Desk_Chairat.lightMap = lightMap;
  materials.Desk_Chairat.lightMapIntensity = 2;
  materials.Desk_Chairat.lightMap.flipY = false;
  materials.Desk_Chairat.envMap = scene.background;
  materials.Desk_Chairat.envMapIntensity = 3;
  materials.Desk_Chairat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desk_Chair.geometry}
        material={materials.Desk_Chairat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Desk_Chair/Desk_Chair.gltf',
  '/optimizedAssets/Desk_Chair/Desk_Chair_Lightmap.jpg',
]);
