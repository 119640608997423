/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Fig_Plant.gltf
Created: Wed May 31 16:43:12 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Fig_Plant/Fig_Plant.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Fig_Plant/Fig_Plant_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Fig_Plant_leaves_mat.lightMap = lightMap;
  materials.Fig_Plant_leaves_mat.lightMapIntensity = 1;
  materials.Fig_Plant_leaves_mat.lightMap.flipY = false;
  materials.Fig_Plant_leaves_mat.envMap = scene.background;
  materials.Fig_Plant_leaves_mat.envMapIntensity = 1;

  materials.Fig_Plant_soil_mat.lightMap = lightMap;
  materials.Fig_Plant_soil_mat.lightMapIntensity = 1;
  materials.Fig_Plant_soil_mat.lightMap.flipY = false;
  materials.Fig_Plant_soil_mat.envMap = scene.background;
  materials.Fig_Plant_soil_mat.envMapIntensity = 1;

  materials.Fig_Plant_trunk_mat.lightMap = lightMap;
  materials.Fig_Plant_trunk_mat.lightMapIntenisty = 2;
  materials.Fig_Plant_trunk_mat.lightMap.flipY = false;
  materials.Fig_Plant_trunk_mat.envMap = lightMap;
  materials.Fig_Plant_trunk_mat.envMapIntensity = 3;
  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fig_Plant_leaves.geometry}
        material={materials.Fig_Plant_leaves_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fig_Plant_soil.geometry}
        material={materials.Fig_Plant_soil_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Fig_Plant_trunk.geometry}
        material={materials.Fig_Plant_trunk_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Fig_Plant/Fig_Plant.gltf',
  '/optimizedAssets/Fig_Plant/Fig_Plant_Lightmap.jpg',
]);
