/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF, useTexture } from '@react-three/drei';
import * as React from 'react';
import * as THREE from 'three';
import Armchairs from './Optimized/Armchairs';

import Books from './Optimized/Books';
import Boxes from './Optimized/Boxes';
import Desk from './Optimized/Desk';
import Cushions from './Optimized/Cushions';
import Ceiling from './Optimized/Ceiling';
import Bookshelves from './Optimized/Bookshelves';
import Env from './Updated/Env';
import Fig from './Optimized/Fig_Plant';
// import Leaves from './Updated/FigPlant/Leaves';
// import Soil from './Updated/FigPlant/Soil';
// import Trunk from './Updated/FigPlant/Trunk';
import Floor from './Optimized/Floor';
import Frames from './Updated/Frames';
import Lamps from './Optimized/Lamps';
import DeskChair from './Optimized/Desk_Chair';
import DoorSkirtings from './Optimized/Door_Skirtings';
import LargeFrames from './Optimized/Large_Frames';
import Lillies from './Optimized/Lillies';
// import LilyVase from './Updated/LilliesPlant/LilyVase';
// import LilyFlower from './Updated/LilliesPlant/LilyFlower';
// import LilyBud from './Updated/LilliesPlant/LilyBud';
// import Map from './Updated/Map';
import Map from './Optimized/Map';
import Mac from './Optimized/Mac';
import Microphone from './Optimized/Microphone';
import Mirror from './Optimized/Mirror';
import Orchid from './Optimized/Orchid';
// import FlowerBuds from './Updated/OrchidPlant/FlowerBuds';
// import Flowers from './Updated/OrchidPlant/Flowers';
// import LargeLeaves from './Updated/OrchidPlant/LargeLeaves';
// import Pebbles from './Updated/OrchidPlant/Pebbles';
// import Roots from './Updated/OrchidPlant/Roots';
// import ShortLeaves from './Updated/OrchidPlant/ShortLeaves';
// import Sticks from './Updated/OrchidPlant/Sticks';
// import SullecentFlowers from './Updated/OrchidPlant/SullecentFlowers';
// import TwigsAndSmallLeaves from './Updated/OrchidPlant/TwigsAndSmallLeaves';
import Pots from './Optimized/Pots';
import Safe from './Optimized/Safe';
import Seashell1 from './Optimized/Seashell_1';
import Seashell2 from './Optimized/Seashell_2';
import Seashell3 from './Optimized/Seashell_3';
import Seashell4 from './Optimized/Seashell_4';
import SetOfBooks from './Optimized/Set_of_books';
// import SetOfBooks from './Updated/SetOfBooks';
import ShellBottoms from './Optimized/Shell_bottoms';
import Sideboards from './Optimized/Sideboards';
import Sofa from './Optimized/Sofa';
import Suitcase from './Optimized/Suitcase';
import Tables from './Optimized/Tables';
import TV from './Optimized/TV';
import Walls from './Optimized/Walls';
import Window from './Optimized/Window';

export default function RoomD({
  cubeMap,
  hoverTarget,
  setHoverTarget,
  setWalkIndicatorPoint,
  moveTo,
  showScreen,
  ...props
}) {
  // const { nodes, materials } = useGLTF('/Room_D.gltf');
  const textures = useTexture({
    tv: 'dynamic-textures/tv.jpg',
    frameBoat: 'dynamic-textures/frame-boat.jpg',
    frameCaterham: 'dynamic-textures/frame-caterham.jpg',
    frameRadical: 'dynamic-textures/frame-radical.jpg',
    computer: 'dynamic-textures/computer.jpg',
  });

  /* const { x, y, z, w, r, r2, r3 } = useControls({
    x: -129,
    y: 140,
    z: -200,
    w: 19,
    r: 1.81,
    r2: 0,
    r3: 0,
  }); */
  //
  return (
    <group {...props} dispose={null}>
      {/* 3 large picture frames 
       <group
         position={[
           0, 0,
           // Move away from wall to stop red outline
           3,
         ]}
       >
         <group>
           <Select enabled={hoverTarget === 'photosSerengeti'}>
             <mesh
               userData={{ hoverTarget: 'photosSerengeti' }}
               geometry={landscapePhotoFrameNodes.pCube275.geometry}
               material={landscapePhotoFrameMaterials.lambert1}
               castShadow
               receiveShadow
               onPointerOver={() => setHoverTarget('photosSerengeti')}
               onPointerOut={() => setHoverTarget(null)}
               onClick={() => {
                 moveTo(new THREE.Vector3(0.4, 1.7, -2.2));
                 setTimeout(() => {
                   showScreen('photosSerengeti');
                 }, 500);
               }}
             />
           </Select>
           <mesh position={[373.5, 176, -306.3]}>
             <planeGeometry args={[103, 71]} />
             <meshPhongMaterial
               map={textures.frameBoat}
               side={THREE.DoubleSide}
             />
           </mesh>
         </group>

         <group position={[-200, 0, 0]}>
           <mesh
             geometry={landscapePhotoFrameNodes.pCube275.geometry}
             material={landscapePhotoFrameMaterials.lambert1}
             castShadow
             receiveShadow
           />
           <mesh position={[373.5, 176, -306.3]}>
             <planeGeometry args={[103, 71]} />
             <meshPhongMaterial
               map={textures.frameCaterham}
               side={THREE.DoubleSide}
             />
           </mesh>
         </group>

         <group position={[200, 0, 0]}>
           <mesh
             geometry={landscapePhotoFrameNodes.pCube275.geometry}
             material={landscapePhotoFrameMaterials.lambert1}
             castShadow
             receiveShadow
           />
           <mesh position={[373.5, 176, -306.3]}>
             <planeGeometry args={[103, 71]} />
             <meshPhongMaterial
               map={textures.frameRadical}
               side={THREE.DoubleSide}
             />
           </mesh>
         </group>
       </group>
      */}
      {/* <group
        position={[
          -290.57,
          -20.55,
          -46.14 +
            // Move away from wall to stop red outline
            3,
        ]}
        scale={0.64}
      >
        <group position={[-350, 0, 0]}>
          <mesh
            geometry={nodes.polySurface6154.geometry}
            material={materials.lambert1}
            castShadow
            receiveShadow
          />
          <mesh position={[1034, 309, -412]}>
            <planeGeometry args={[150, 110]} />
            <meshPhongMaterial
              map={textures.frameCaterham}
              side={THREE.DoubleSide}
            />
          </mesh>
        </group>
        <group>
           <Select enabled={hoverTarget === 'photosSerengeti'}>
             <mesh
               geometry={nodes.polySurface6154.geometry}
               material={materials.lambert1}
               castShadow
               receiveShadow
               onPointerOver={() => setHoverTarget('photosSerengeti')}
               onPointerOut={() => setHoverTarget(null)}
               onClick={() => {
                 moveTo(new THREE.Vector3(0.4, 1.7, -2.2));
                 setTimeout(() => {
                   showScreen('photosSerengeti');
                 }, 500);
               }}
             />
           </Select>
           <mesh position={[1034, 309, -412]}>
             <planeGeometry args={[150, 110]} />
             <meshPhongMaterial
               map={textures.frameBoat}
               side={THREE.DoubleSide}
             />
           </mesh>
         </group>

        <group position={[338, 0, 0]}>
          <mesh
            geometry={nodes.polySurface6154.geometry}
            material={materials.lambert1}
            castShadow
            receiveShadow
          />
          <mesh position={[1034, 309, -412]}>
            <planeGeometry args={[150, 110]} />
            <meshPhongMaterial
              map={textures.frameRadical}
              side={THREE.DoubleSide}
            />
          </mesh>
        </group>
      </group> */}
      {/* <mesh
        geometry={nodes.polySurface8267.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.polySurface8284.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Large picture frame above desk */}
      {/* <PhotoFrame /> */}
      {/* <group position={[-144.41, 19.01, 80.09]} scale={0.57}>
        <mesh
          geometry={nodes.polySurface6149.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
      </group> */}
      {/* All lamp above ??
      <group
        position={[-3407.87, 267.66, -1107.48]}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <mesh
          geometry={nodes.lighting1.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.lighting2.geometry}
          material={materials.lambert1}
          position={[549.32, -179.9, 11.66]}
          scale={0.86}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.lighting3.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.lighting4.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.lighting5.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
      </group>
      */}
      {/* Side table */}
      {/* <group position={[0, 0, 4.27]}>
        <mesh
          geometry={nodes.polySurface10327.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.polySurface10328.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={nodes.polySurface10329.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
        />

        {/ <mesh
          geometry={nodes.polySurface10330.geometry}
          material={materials.lambert1}
          castShadow
          receiveShadow
          /> /}
      </group> */}
      /*
      {/* R Bookshelf 3 flat top books (moving to left) 
      <group position={[0, -139, 470]}>
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8482.geometry}
          material={bookshelfBBooksMaterials.MotorRacing_Book}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8483.geometry}
          material={bookshelfBBooksMaterials.TheKids_Book}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8494.geometry}
          material={bookshelfBBooksMaterials.Yachting_Book}
          castShadow
          receiveShadow
        />
      </group>
      <mesh
        geometry={bookshelfBBooksNodes.polySurface8509.geometry}
        material={bookshelfBBooksMaterials.Static_Books}
        castShadow
        receiveShadow
      />
    */}
      {/* R Bookshelf 3 flat bottom books (moving to top) 
      <group position={[0, 91, 60]}>
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8498.geometry}
          material={bookshelfBBooksMaterials.BookA}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8500.geometry}
          material={bookshelfBBooksMaterials.BookB}
          castShadow
          receiveShadow
        />
        <mesh
          geometry={bookshelfBBooksNodes.polySurface8499.geometry}
          material={bookshelfBBooksMaterials.BookC}
          castShadow
          receiveShadow
        />
      </group>
      */}
      {/* RBookshelf photoframe */}
      {/* <mesh
        geometry={nodes.polySurface8569.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* TV 
      <group>
         <Select enabled={hoverTarget === 'tv'}>
           <mesh
             userData={{ hoverTarget: 'tv' }}
             geometry={tvNodes.TV1.geometry}
             material={tvMaterials.lambert1}
             onPointerOver={() => setHoverTarget('tv')}
             onPointerOut={() => setHoverTarget(null)}
             onClick={() => {
               moveTo(new THREE.Vector3(-3.9, 1.7, 0));
               setTimeout(() => {
                 showScreen('photosFriends');
               }, 500);
             }}
           />
         </Select>
         <mesh position={[-152, 188.5, 9]} rotation={[0, Math.PI / 2, 0]}>
           <planeGeometry args={[220, 104]} />
           <meshPhongMaterial map={textures.tv} side={THREE.DoubleSide} />
         </mesh>
       </group>
        <group>
         <Select enabled={hoverTarget === 'tv'}>
           <mesh
             geometry={nodes.TV1.geometry}
             material={materials.lambert1}
             onPointerOver={() => setHoverTarget('tv')}
             onPointerOut={() => setHoverTarget(null)}
             onClick={() => {
               moveTo(new THREE.Vector3(-3.9, 1.7, 0));
               setTimeout(() => {
                 showScreen('photosFriends');
               }, 500);
             }}
           />
         </Select>
         <mesh position={[-152, 188.5, 9]} rotation={[0, Math.PI / 2, 0]}>
           <planeGeometry args={[220, 104]} />
           <meshPhongMaterial map={textures.tv} side={THREE.DoubleSide} />
         </mesh>
       </group> */}
      <TV
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('tv')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(-1, 1.7, 1.6));
          setTimeout(() => {
            showScreen('photosFriends');
          }, 500);
        }}
      />
      {/* Mosaic photo frames */}
      {/* <MosaicPhotoFrame /> */}
      {/* <mesh
        geometry={nodes.polySurface437.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
          /> */}
      {/* Door */}
      {/*<Door />*/}
      {/* <mesh
        geometry={nodes.polySurface1560.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Desk chair 
      <DeskChair /> */}
      {/* <mesh
        geometry={nodes.polySurface1988.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Sofa 
      <Sofa /> */}
      {/* <mesh
        geometry={nodes.polySurface2884.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Footstool */}
      {/*<Footstool />*/}
      {/* <mesh
        geometry={nodes.polySurface2885.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Rug */}
      {/*<Rug />*/}
      {/* <mesh
        geometry={nodes.ChamferBox001.geometry}
        material={materials.lambert1}
        castShadow
        receiveShadow
      /> */}
      {/* Armchairs */}
      <Armchairs cubeMap={cubeMap} />
      <Mac
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('computer')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(1.75, 1.4, 1.4));
          setTimeout(() => {
            showScreen('videoWelcome');
          }, 500);
        }}
      />
      <Safe
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('safe')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(-2.5, 0.8, -1.5));
          setTimeout(() => {
            showScreen('safe');
          }, 500);
        }}
      />
      <Books />
      <Bookshelves />
      <Boxes />
      <Ceiling />
      <Cushions />
      <Desk />
      <Env />
      {/* Fig Plant */}
      {/* <Leaves />
      <Soil />
      <Trunk /> */}
      <Fig />
      <Floor
      // Uncomment to help set walkable area
      /* onPointerMove={(ev) => {
          console.log(ev.point);
        }} */
      />
      <Frames />
      <Lamps />
      <DeskChair />
      <DoorSkirtings />
      <LargeFrames
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('photosSerengeti')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(-0.25, 2.0, -1.2));
          setTimeout(() => {
            showScreen('photosSerengeti');
          }, 500);
        }}
      />
      <Lillies />
      {/* <LilyBud />
      <LilyFlower />
      <LilyVase /> */}
      <Map
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('travelTracker')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(3, 1.8, 1.7));
          setTimeout(() => {
            showScreen('travelTracker');
          }, 500);
        }}
      />
      <Microphone
        hoverTarget={hoverTarget}
        onPointerOver={() => setHoverTarget('timeCapsuel')}
        onPointerOut={() => setHoverTarget(null)}
        onClick={() => {
          moveTo(new THREE.Vector3(-2.8, 1.8, 2));
          setTimeout(() => {
            showScreen('timeCapsuel');
          }, 500);
        }}
      />
      <Mirror />
      {/* Orchid Plant */}
      {/* <FlowerBuds />
      <Flowers />
      <LargeLeaves />
      <Pebbles />
      <Roots />
      <ShortLeaves />
      <Sticks />
      <SullecentFlowers />
      <TwigsAndSmallLeaves /> */}
      <Orchid />
      <Pots />
      <Seashell1 />
      <Seashell2 />
      <Seashell3 />
      <Seashell4 />
      <SetOfBooks
        hoverTarget={hoverTarget}
        onPointerOut={() => setHoverTarget(null)}
        onPointerOverPassions={() => setHoverTarget('passionsAndPastimes')}
        onClickPassions={() => {
          moveTo(new THREE.Vector3(1.35, 1, -0.1));
          setTimeout(() => {
            showScreen('passionsAndPastimes');
          }, 500);
        }}
        onPointerOverLife={() => setHoverTarget('lifeStory')}
        onClickLife={() => {
          moveTo(new THREE.Vector3(-2.8, 1.95, 1.9));
          setTimeout(() => {
            showScreen('photosLifeStory');
          }, 500);
        }}
      />
      <ShellBottoms />
      <Sideboards />
      <Sofa />
      <Suitcase />
      <Tables />
      <Walls />
      <Window />
    </group>
  );
}

// useGLTF.preload('/3d/BookshelfB/BookshelfB.gltf');
// useGLTF.preload('/3d/BookshelfA_Books/BookshelfA_Books.gltf');
// useGLTF.preload('/3d/BookshelfB_Books/BookshelfB_Books.gltf');
// useGLTF.preload('/3d/LandscapePhotoFrame/LandscapePhotoFrame.gltf');
// useGLTF.preload('/Room_D.gltf');
// useGLTF.preload('/3d/Desk/Desk.gltf');
// useGLTF.preload('/3d/TV/TV.gltf');
