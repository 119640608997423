/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/optimizedAssets/Microphone/Microphone.gltf
Created: Mon Jun  5 16:26:25 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';
export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Microphone/Microphone.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Microphone/Microphone_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Microphone_mat.lightMap = lightMap;
  materials.Microphone_mat.lightMapIntensity = 2;
  materials.Microphone_mat.lightMap.flipY = false;
  materials.Microphone_mat.envMap = scene.background;
  materials.Microphone_mat.envMapIntensity = 3;

  return (
    <group dispose={null}>
      <Select enabled={props.hoverTarget === 'timeCapsuel'}>
        <group {...props} scale={100} userData={{ hoverTarget: 'timeCapsuel' }}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Microphone.geometry}
            material={materials.Microphone_mat}
          />
        </group>
      </Select>
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Microphone/Microphone.gltf',
  '/optimizedAssets/Microphone/Microphone_Lightmap.jpg',
]);
