import { ResizeObserver } from '@juggle/resize-observer';
import { Canvas } from '@react-three/fiber';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import * as THREE from 'three';

import Scene from './Scene';
import {
  LandingScreen,
  PhotosFriendsScreen,
  PhotosLifeStoryScreen,
  PhotosSerengetiScreen,
  SafeScreen,
  VideoWelcomeScreen,
  PassionsAndPastimesScreen,
  TravelTrackerScreen,
  TimeCapsuleScreen,
} from './screens';

export const INITIAL_TARGET = new THREE.Vector3(4.4, 1.7, 0);
export const INITIAL_CAM_POS = INITIAL_TARGET.clone().add(
  new THREE.Vector3(0.1, 0, 0)
);

export default function Root() {
  const [lastScreen, setLastScreen] = React.useState<
    | 'landing'
    | 'photosFriends'
    | 'photosLifeStory'
    | 'photosSerengeti'
    | 'safe'
    | 'videoWelcome'
    | 'travelTracker'
    | 'timeCapsuel'
    | 'passionsAndPastimes'
  >('landing');
  const [screenVisible, setScreenVisible] = React.useState(true);

  const showScreen = (screenName) => {
    setLastScreen(screenName);
    setScreenVisible(true);
  };

  const orbitRef = React.useRef();

  const [desiredTargetPoint, setDesiredTargetPoint] =
    React.useState(INITIAL_TARGET);
  const [popBackTargetPoint, setPopBackTargetPoint] =
    React.useState(INITIAL_TARGET);

  const [currentAnimVector, setCurrentAnimVector] =
    React.useState<THREE.Vector3 | null>(null);
  const [currentAnimStartDistance, setCurrentAnimStartDistance] =
    React.useState<number | null>(null);

  const moveTo = React.useCallback(
    (target: THREE.Vector3, cameraPos?: THREE.Vector3): void => {
      const diff = target.clone().sub(orbitRef.current.target).normalize();
      setDesiredTargetPoint(target);
      setPopBackTargetPoint(orbitRef.current.target.clone());
      setCurrentAnimVector(diff);
      setCurrentAnimStartDistance(target.distanceTo(orbitRef.current.target));
    },
    [desiredTargetPoint]
  );

  const onDismissOverlay = () => {
    setScreenVisible(false);
    setTimeout(() => {
      moveTo(popBackTargetPoint);
    }, 100);
  };

  return (
    <>
      <Canvas
        // Was causing bugs on some platforms after closing an overlay
        // frameloop={screenVisible ? 'never' : 'demand'}
        frameloop="demand"
        resize={{
          // Polyfill for old iPad
          polyfill: ResizeObserver,
        }}
        shadows
        linear
      >
        <React.Suspense fallback={null}>
          <Scene
            currentAnimVector={currentAnimVector}
            currentAnimStartDistance={currentAnimStartDistance}
            desiredTargetPoint={desiredTargetPoint}
            moveTo={moveTo}
            orbitRef={orbitRef}
            setCurrentAnimVector={setCurrentAnimVector}
            setCurrentAnimStartDistance={setCurrentAnimStartDistance}
            showScreen={showScreen}
          />
        </React.Suspense>
      </Canvas>
      {/* <Stats /> */}
      <View
        pointerEvents={screenVisible ? 'auto' : 'none'}
        style={[
          StyleSheet.absoluteFill,
          {
            zIndex: 100,
            opacity: screenVisible ? 1 : 0,
            ['transitionProperty' as any]: 'all',
            ['transitionDuration' as any]: '500ms',
          },
        ]}
      >
        {lastScreen === 'landing' && (
          <LandingScreen onDismiss={() => setScreenVisible(false)} />
        )}
        {lastScreen === 'photosFriends' && (
          <PhotosFriendsScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'photosLifeStory' && (
          <PhotosLifeStoryScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'photosSerengeti' && (
          <PhotosSerengetiScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'safe' && <SafeScreen onDismiss={onDismissOverlay} />}
        {/* Video causes problems if it remains loaded with opacity:0 */}
        {lastScreen === 'videoWelcome' && screenVisible && (
          <VideoWelcomeScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'passionsAndPastimes' && (
          <PassionsAndPastimesScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'timeCapsuel' && (
          <TimeCapsuleScreen onDismiss={onDismissOverlay} />
        )}
        {lastScreen === 'travelTracker' && (
          <TravelTrackerScreen onDismiss={onDismissOverlay} />
        )}
      </View>
    </>
  );
}
