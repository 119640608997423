/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Desk.gltf
Created: Wed May 31 15:57:27 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Desk/Desk.gltf');
  const lightMap = useTexture('/optimizedAssets/Desk/Desk_Lightmap.jpg');
  const { scene } = useThree();

  materials.Desk_mat.lightMap = lightMap;
  materials.Desk_mat.lightMapIntensity = 3;
  materials.Desk_mat.lightMap.flipY = false;
  materials.Desk_mat.envMap = scene.background;
  materials.Desk_mat.envMapIntensity = 3;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desk.geometry}
        material={materials.Desk_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Desk/Desk.gltf',
  '/optimizedAssets/Desk/Desk_Lightmap.jpg',
]);
