import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Color } from '../constants';
import LegacyText from './LegacyText';

type Props = {
  label: string;
  onPress: () => void;
  disabled?: boolean;
  colorScheme?: 'light' | 'dark';
};

export default function BlockButton({
  label,
  onPress,
  disabled,
  colorScheme = 'light',
}: Props) {
  let textColor = colorScheme === 'light' ? '#131212' : Color.white;
  if (disabled) {
    textColor = '#828282';
  }

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      style={({ hovered }) => [
        styles.button,
        colorScheme === 'light' ? styles.buttonLight : styles.buttonDark,
        hovered &&
          (colorScheme === 'light'
            ? styles.buttonLightHovered
            : styles.buttonDarkHovered),
        disabled && styles.buttonDisabled,
      ]}
    >
      <LegacyText typeface="button" color={textColor}>
        {label}
      </LegacyText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingVertical: 17,
    paddingHorizontal: 70,
    borderRadius: 12,
    transitionProperty: 'all',
    transitionDuration: '250ms',
  },
  buttonLight: {
    backgroundColor: Color.white,
  },
  buttonDark: {
    backgroundColor: Color.background,
  },
  buttonLightHovered: {
    backgroundColor: '#ddd',
  },
  buttonDarkHovered: {
    backgroundColor: '#111',
  },
  buttonDisabled: {
    backgroundColor: '#D9D9D9',
  },
});
