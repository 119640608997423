/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Seashell_4.gltf
Created: Wed May 31 12:01:19 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Seashell_4/Seashell_4.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Seashell_4/Seashell_4_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials['Seashell_4_mat'].lightMap = lightMap;
  materials['Seashell_4_mat'].lightMapIntensity = 0;
  materials['Seashell_4_mat'].lightMap.flipY = false;
  materials['Seashell_4_mat'].envMap = scene.background;
  materials['Seashell_4_mat'].envMapIntensity = 2;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Seashell_4.geometry}
        material={materials['Seashell_4_mat']}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Seashell_4/Seashell_4.gltf',
  '/optimizedAssets/Seashell_4/Seashell_4_Lightmap.jpg',
]);
