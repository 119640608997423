import * as React from 'react';
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';

import { LegacyText, SimpleButton, Spacer } from '../components';
import {
  IconChevronLeft,
  IconChevronRight,
  IconCrossBig,
} from '../components/icons';
import { Dimen, Style } from '../constants';

type Props = {
  onDismiss: () => void;
  title?: string;
  description?: string;
  slides: Array<{
    photoUrl?: string;
    videoUrl?: string;
  }>;
};

export default function Slideshow({ onDismiss, title, description, slides }) {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const hasPrev = currentIndex > 0;
  const hasNext = currentIndex < slides.length - 1;
  const currentSlide = slides[currentIndex];

  const videoRef = React.useRef<HTMLVideoElement>();

  const { width: windowWidth } = useWindowDimensions();
  const isCompactLayout = windowWidth < 700;

  const textOnlyMode = !currentSlide;

  const finalOnDismiss = () => {
    onDismiss();
    videoRef.current?.pause();
  };

  return (
    <>
      <View
        style={[
          StyleSheet.absoluteFill,
          { backgroundColor: '#000a', cursor: 'default' },
        ]}
      >
        <View
          style={[Style.fill, Style.row, Style.alignCenter, Style.spaceBetween]}
        >
          <View
            style={[
              styles.arrowHolder,
              isCompactLayout && styles.arrowHolderCompact,
              isCompactLayout && { left: 0 },
            ]}
          >
            {hasPrev && (
              <SimpleButton
                icon={IconChevronLeft}
                onPress={() => setCurrentIndex(currentIndex - 1)}
              />
            )}
          </View>
          <View style={[styles.areaMain, textOnlyMode && { maxWidth: 680 }]}>
            {slides.length > 1 && (
              <View
                style={[
                  isCompactLayout && {
                    padding: Dimen.spacing * 0.5,
                    paddingBottom: 0,
                  },
                ]}
              >
                <LegacyText typeface="body2">
                  {currentIndex + 1} / {slides.length}
                </LegacyText>
                <Spacer />
              </View>
            )}
            {currentSlide && (
              <>
                {currentSlide.photoUrl && (
                  <Image
                    source={{ uri: currentSlide.photoUrl }}
                    style={{ flex: 1, maxHeight: 620, width: '100%' }}
                    resizeMode="contain"
                  />
                )}
                {currentSlide.videoUrl && (
                  <video
                    ref={videoRef}
                    style={{ flex: 1, maxHeight: 620, width: '100%' }}
                    controls
                    autoPlay
                    controlsList="nodownload nofullscreen"
                  >
                    <source src={currentSlide.videoUrl} type="video/mp4" />
                  </video>
                )}
                <Spacer />
              </>
            )}
            <View
              style={[
                isCompactLayout && {
                  padding: Dimen.spacing * 0.5,
                  paddingTop: 0,
                },
              ]}
            >
              {title && (
                <LegacyText typeface={textOnlyMode ? 'h2' : 'body1'}>
                  {title}
                </LegacyText>
              )}
              {description && (
                <>
                  <Spacer />
                  <LegacyText typeface={textOnlyMode ? 'body1' : 'body2'}>
                    {description}
                  </LegacyText>
                </>
              )}
            </View>
          </View>
          <View
            style={[
              styles.arrowHolder,
              isCompactLayout && styles.arrowHolderCompact,
              isCompactLayout && { right: 0 },
            ]}
          >
            {hasNext && (
              <SimpleButton
                icon={IconChevronRight}
                onPress={() => setCurrentIndex(currentIndex + 1)}
              />
            )}
          </View>
        </View>

        <View
          style={{
            position: 'absolute',
            top: Dimen.spacing,
            right: Dimen.spacing,
          }}
        >
          <SimpleButton
            onPress={finalOnDismiss}
            xlMode
            icon={(props) => <IconCrossBig {...props} />}
          />
        </View>
      </View>

      {/* Preload other photos */}
      <div style={{ position: 'fixed', left: 5000, top: 5000 }}>
        {slides.map((slide) =>
          slide.photoUrl ? (
            <Image
              source={{ uri: slide.photoUrl }}
              style={{ width: 10, height: 10 }}
              resizeMode="contain"
            />
          ) : null
        )}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  arrowHolder: {
    width: 120,
    alignItems: 'center',
  },
  arrowHolderCompact: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    zIndex: 50,
    width: 60,
  },
  areaMain: {
    alignSelf: 'stretch',
    maxWidth: 1024,
    flex: 1,
    justifyContent: 'center',
  },
});
