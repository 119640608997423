/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/optimizedAssets/Armchairs/Armchairs.gltf
Created: Mon May 22 10:54:56 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Armchairs/Armchairs.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Armchairs/Armchairs_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.ARMCHAIRS_fabric.lightMap = lightMap;
  materials.ARMCHAIRS_fabric.lightMapIntensity = 5;
  materials.ARMCHAIRS_fabric.lightMap.flipY = false;
  materials.ARMCHAIRS_fabric.envMap = scene.background;
  materials.ARMCHAIRS_fabric.envMapIntensity = 3;
  materials.ARMCHAIRS_fabric.aoMap = lightMap;

  materials.ARMCHAIRS_wood.lightMap = lightMap;
  materials.ARMCHAIRS_wood.lightMapIntensity = 2;
  materials.ARMCHAIRS_wood.lightMap.flipY = false;
  materials.ARMCHAIRS_wood.envMap = scene.background;
  materials.ARMCHAIRS_wood.envMapIntensity = 3;

  materials.ARMCHAIRS_metal.lightMap = lightMap;
  materials.ARMCHAIRS_metal.lightMapIntensity = 2;
  materials.ARMCHAIRS_metal.lightMap.flipY = false;
  materials.ARMCHAIRS_metal.envMap = scene.background;
  materials.ARMCHAIRS_metal.envMapIntensity = 3;
  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        geometry={nodes.ARMCHAIRS_fabric.geometry}
        material={materials.ARMCHAIRS_fabric}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.ARMCHAIRS_wood.geometry}
        material={materials.ARMCHAIRS_wood}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.ARMCHAIRS_metal.geometry}
        material={materials.ARMCHAIRS_metal}
        castShadow
        receiveShadow
      />
    </group>
  );
}

useGLTF.preload(
  '/optimizedAssets/Armchairs/Armchairs.gltf',
  '/optimizedAssets/Armchairs/Armchairs_Lightmap.jpg'
);
