/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Boxes.gltf
Created: Wed May 31 11:20:39 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Boxes/Boxes.gltf');
  const lightMapTexture = useTexture(
    '/optimizedAssets/Boxes/Boxes_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Boxes_mat.lightMap = lightMapTexture;
  materials.Boxes_mat.lightMapIntensity = 5;
  materials.Boxes_mat.lightMap.flipY = false;
  materials.Boxes_mat.envMap = scene.background;
  materials.Boxes_mat.envMapIntensity = 3;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Boxes.geometry}
        material={materials.Boxes_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Boxes/Boxes.gltf',
  '/optimizedAssets/Boxes/Boxes_Lightmap.jpg',
]);
