import { useFonts } from "expo-font";
import * as React from "react";
import Root from "./src/Root";

export default function App() {
  const [fontsLoaded] = useFonts({
    "GeneralSans-Medium": require("./assets/fonts/GeneralSans-Medium.otf"),
    "GeneralSans-Regular": require("./assets/fonts/GeneralSans-Regular.otf"),
  });

  if (!fontsLoaded) {
    return "Loading initial assets";
  }

  return <Root />;
}
