/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Lillies.gltf
Created: Tue May 30 22:29:12 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Lillies/Lillies.gltf');
  const lightMap = useTexture('/optimizedAssets/Lillies/Lillies_Lightmap.jpg');
  const { scene } = useThree();

  materials.Lillies_flowers.lightMap = lightMap;
  materials.Lillies_flowers.lightMapIntensity = 1.1;
  materials.Lillies_flowers.lightMap.flipY = false;
  materials.Lillies_flowers.envMap = scene.background;
  materials.Lillies_flowers.envMapIntensity = 1.1;

  materials.Lillies_vase.lightMap = lightMap;
  materials.Lillies_vase.lightMapIntensity = 0;
  materials.Lillies_vase.lightMap.flipY = false;
  materials.Lillies_vase.envMap = scene.background;
  materials.Lillies_vase.envMapIntensity = 1.5;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lillies_flowers.geometry}
        material={materials.Lillies_flowers}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lillies_vase.geometry}
        material={materials.Lillies_vase}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Lillies/Lillies.gltf',
  '/optimizedAssets/Lillies/Lillies_Lightmap.jpg',
]);
