import { useProgress } from '@react-three/drei';
import * as React from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

import {
  BlockButton,
  Keypad,
  LegacyText,
  PaddedArea,
  Spacer,
} from '../components';
import { Dimen, Style } from '../constants';

type Props = {
  onDismiss: () => void;
};

export default function LandingScreen({ onDismiss }: Props) {
  const { active, progress, errors, item, loaded, total } = useProgress();
  const { width: windowWidth } = useWindowDimensions();
  const desktopMode = windowWidth >= 1200;
  const mobileMode = windowWidth <= Dimen.breakpointMaxMobile;
  const [keypadVisible, setKeypadVisible] = React.useState(false);

  if (keypadVisible) {
    return (
      <Keypad
        style={StyleSheet.absoluteFill}
        onDismiss={() => setKeypadVisible(false)}
        onSuccess={onDismiss}
      />
    );
  }

  return (
    <ImageBackground
      source={require('../../assets/img/landing-bg.jpg')}
      style={[
        StyleSheet.absoluteFill,
        { backgroundColor: '#161616', flexDirection: 'row' },
      ]}
    >
      <PaddedArea v h style={Style.fill}>
        <Image
          source={require('../../assets/img/logo-new.svg')}
          style={{ width: 57, height: 57 }}
        />
        <View style={[Style.fill, Style.centerBoth]}>
          <View style={[Style.alignStart, { width: '100%', maxWidth: 800 }]}>
            <LegacyText typeface="h1">Legacy Room</LegacyText>
            <Spacer size={1.5} />
            <LegacyText typeface={mobileMode ? 'h3' : 'h2'}>
              This is very much a stage one demonstration of the concept. Future
              development will enhance the features and will provide a more
              personal and interactive experience. Use code 19 11 60 to enter.
            </LegacyText>
            <Spacer size={1.5} />
            {!desktopMode && (
              <BlockButton
                label="Enter room"
                onPress={() => setKeypadVisible(true)}
              />
            )}
          </View>
        </View>
      </PaddedArea>
      {desktopMode && (
        <Keypad style={{ flex: 0.5, minWidth: 500 }} onSuccess={onDismiss} />
      )}
    </ImageBackground>
  );
}
