import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function TimeCapsuleScreen({ onDismiss }: Props) {
  return (
    <Slideshow
      onDismiss={onDismiss}
      slides={[{ videoUrl: '/video/Legacy Room Audio Travel Capsule Animation v1.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="Time Capsule"
    //   description="Record audio, video, email or  text messages to send to friends and family in the future. A grandchild’s 21st birthday or wedding day? Send messages in the future using the Time Capsule feature, you can choose when and who will receive the messages, or if you don’t know the date you can assign a keeper of the message until the day arrives."
    //   slides={[{ photoUrl:'/img/galleries/time-capsule/image0.jpeg' }]}
    // />
  );
}
