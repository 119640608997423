/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Tables.gltf
Created: Mon May 29 01:10:32 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('optimizedAssets/Tables/Tables.gltf');
  const lightMap = useTexture('optimizedAssets/Tables/Tables_Lightmap.jpg');
  const { scene } = useThree();

  materials.Tables_metall_mat.lightMap = lightMap;
  materials.Tables_metall_mat.lightMapIntensity = 0;
  materials.Tables_metall_mat.lightMap.flipY = false;
  materials.Tables_metall_mat.envMap = scene.background;
  materials.Tables_metall_mat.envMapIntenisty = 2;

  materials.Table_glass_mat.lightMap = lightMap;
  materials.Table_glass_mat.lightMapIntensity = 1;
  materials.Table_glass_mat.lightMap.flipY = false;
  materials.Table_glass_mat.envMap = scene.background;
  materials.Table_glass_mat.envMapIntensity = 8;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tables_metall.geometry}
        material={materials.Tables_metall_mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tables_glass.geometry}
        material={materials.Table_glass_mat}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Tables/Tables.gltf',
  'optimizedAssets/Tables/Tables_Lightmap.jpg',
]);
