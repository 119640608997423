/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Map.gltf
Created: Wed May 31 18:35:36 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Map/Map.gltf');
  const lightMap = useTexture('/optimizedAssets/Map/Map_Lightmap.jpg');
  const { scene } = useThree();

  materials.Map_mat.lightMap = lightMap;
  materials.Map_mat.lightMapIntensity = 2;
  materials.Map_mat.lightMap.flipY = false;
  materials.Map_mat.envMap = scene.background;
  materials.Map_mat.envMapIntensity = 3;

  return (
    <group dispose={null}>
      <Select enabled={props.hoverTarget === 'travelTracker'}>
        <group
          {...props}
          scale={100}
          userData={{ hoverTarget: 'travelTracker' }}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Map.geometry}
            material={materials.Map_mat}
          />
        </group>
      </Select>
    </group>
  );
}

useGLTF.preload('/optimizedAssets/Map/Map.gltf');
