/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 TV.gltf
Created: Sun May 28 23:44:37 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('optimizedAssets/TV/TV.gltf');
  const { scene } = useThree();

  materials.TV_mat.envMap = scene.background;
  materials.TV_mat.envMapIntensity = 2;

  return (
    <group dispose={null}>
      <Select enabled={props.hoverTarget === 'tv'}>
        <group {...props} scale={100} userData={{ hoverTarget: 'tv' }}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.TV.geometry}
            material={materials.TV_mat}
          />
        </group>
      </Select>
    </group>
  );
}

useGLTF.preload('optimizedAssets/TV/TV.gltf');
