import * as React from 'react';
import { Pressable, StyleSheet, useWindowDimensions, View } from 'react-native';

import { Color } from '../constants';
import { IconCrossBig } from './icons';

type Props = {
  onPress: () => void;
};

export default function BackspaceButton({ onPress }: Props) {
  const buttonSize = 78;
  return (
    <View
      style={{
        width: buttonSize,
        height: buttonSize,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Pressable style={styles.button} onPress={onPress}>
        {({ hovered, pressed }) => (
          <>
            <View
              style={[
                styles.arrow,
                hovered && { borderRightColor: '#aaa' },
                pressed && { borderRightColor: '#888' },
              ]}
            />
            <View
              style={[
                styles.box,
                hovered && { backgroundColor: '#aaa' },
                pressed && { backgroundColor: '#888' },
              ]}
            >
              <IconCrossBig size={28} color="#000000" />
            </View>
          </>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  box: {
    backgroundColor: '#c3c1be',
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '100ms',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  arrow: {
    width: 0,
    height: 0,
    borderTopWidth: 16,
    borderBottomWidth: 16,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightWidth: 16,
    borderRightColor: '#c3c1be',
    transitionProperty: 'border-right-color',
    transitionDuration: '100ms',
  },
});
