/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Sideboards.gltf
Created: Wed May 31 09:04:42 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    'optimizedAssets/Sideboards/Sideboards.gltf'
  );
  const lightMap = useTexture(
    'optimizedAssets/Sideboards/Sideboards_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Sideboards_mat.lightMap = lightMap;
  materials.Sideboards_mat.lightMapIntensity = 2.5;
  materials.Sideboards_mat.lightMap.flipY = false;
  materials.Sideboards_mat.envMap = scene.background;
  materials.Sideboards_mat.envMapIntensity = 5;
  materials.Sideboards_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sideboards.geometry}
        material={materials.Sideboards_mat}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Sideboards/Sideboards.gltf',
  'optimizedAssets/Sideboards/Sideboards_Lightmap.jpg',
]);
