/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Set_of_books.gltf
Created: Tue May 30 23:38:22 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';

export default function Model({
  hoverTarget,
  onPointerOverPassions,
  onClickPassions,
  onPointerOut,
  onPointerOverLife,
  onClickLife,
}) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Set_of_books/Set_of_books.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Set_of_books/Set_of_books_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Set_of_books_mat.lightMap = lightMap;
  materials.Set_of_books_mat.lightMapIntensity = 2.5;
  materials.Set_of_books_mat.lightMap.flipY = false;
  materials.Set_of_books_mat.envMap = scene.background;
  materials.Set_of_books_mat.envMapIntensity = 3;

  return (
    <group dispose={null}>
      <group scale={100}>
        <Select enabled={hoverTarget === 'passionsAndPastimes'}>
          <mesh
            onPointerOver={onPointerOverPassions}
            onPointerOut={onPointerOut}
            onClick={onClickPassions}
            geometry={nodes.Book_007.geometry}
            material={materials.Set_of_books_mat}
            userData={{ hoverTarget: 'passionsAndPastimes' }}
          />
        </Select>
        <mesh
          geometry={nodes.Book_001.geometry}
          material={materials.Set_of_books_mat}
        />
        <mesh
          geometry={nodes.Book_002.geometry}
          material={materials.Set_of_books_mat}
        />
        <mesh
          geometry={nodes.Book_003.geometry}
          material={materials.Set_of_books_mat}
        />
        <mesh
          geometry={nodes.Book_004.geometry}
          material={materials.Set_of_books_mat}
        />
        <mesh
          geometry={nodes.Book_005.geometry}
          material={materials.Set_of_books_mat}
        />
        <Select enabled={hoverTarget === 'lifeStory'}>
          <mesh
            onPointerOver={onPointerOverLife}
            onPointerOut={onPointerOut}
            onClick={onClickLife}
            geometry={nodes.Book_006.geometry}
            material={materials.Set_of_books_mat}
            userData={{ hoverTarget: 'lifeStory' }}
          />
        </Select>
      </group>
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Set_of_books/Set_of_books.gltf',
  '/optimizedAssets/Set_of_books/Set_of_books_Lightmap.jpg',
]);
