/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 assets/updatedAssets/Env/Env.gltf
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/updatedAssets/Env/Env.gltf')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.ENV.geometry} material={materials.ENV1} rotation={[Math.PI / 2, 0, 0]}  />
    </group>
  )
}

useGLTF.preload('/updatedAssets/Env/Env.gltf')
