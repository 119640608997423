/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Window.gltf
Created: Sun May 28 22:30:46 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('optimizedAssets/Window/window.gltf');
  const lightMap = useTexture('optimizedAssets/Window/window_Lightmap.jpg');
  const { scene } = useThree();

  materials.Window_mat.lightMap = lightMap;
  materials.Window_mat.lightMapIntensity = 1;
  materials.Window_mat.lightMap.flipY = false;
  materials.Window_mat.envMap = scene.background;
  materials.Window_mat.envMapIntensity = 1;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window.geometry}
        material={materials.Window_mat}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Window/window.gltf',
  'optimizedAssets/Window/window_Lightmap.jpg',
]);
