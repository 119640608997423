/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Ceiling.gltf
Created: Sun May 28 23:13:54 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Ceiling/Ceiling.gltf');
  const lightMap = useTexture(
    '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Ceiling_mat.lightMap = lightMap;
  materials.Ceiling_mat.lightMapIntensity = 1.5;
  materials.Ceiling_mat.lightMap.flipY = false;
  materials.Ceiling_mat.envMap = scene.background;
  materials.Ceiling_mat.envMapIntensity = 1.5;
  materials.Ceiling_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        receiveShadow
        geometry={nodes.Ceiling.geometry}
        material={materials.Ceiling_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Ceiling/Ceiling.gltf',
  '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg',
]);
