import * as React from 'react';
import { Pressable, StyleSheet, useWindowDimensions } from 'react-native';

import { Color } from '../constants';
import { IconCrossBig } from './icons';

type Props = {
  onPress: () => void;
  icon: (props: { color: string }) => React.ReactNode;
  xlMode?: boolean;
  darkContent?: boolean;
};

export default function SimpleButton({
  onPress,
  icon,
  xlMode,
  darkContent,
}: Props) {
  const { width: windowWidth } = useWindowDimensions();
  const allowXl = windowWidth > 500;

  const buttonSize = allowXl && xlMode ? 80 : 48;
  return (
    <Pressable
      style={({ hovered, pressed }) => [
        styles.button,
        { width: buttonSize, height: buttonSize },
        hovered && { backgroundColor: darkContent ? '#0001' : '#fff1' },
        pressed && { backgroundColor: darkContent ? '#0003' : '#fff3' },
      ]}
      onPress={onPress}
    >
      {icon({ color: darkContent ? '#0d0d0d' : Color.white })}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 42,
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '150ms',
  },
});
