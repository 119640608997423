import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function PhotosSerengetiScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20-sec) Friends Wall Animation v3.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="Steve Double"
    //   description="So you have clicked on my friend Steve Double.AKA Dubs
    //   He and I met when we were 17. We are best mates. This part of my legacy room will include images video and text compiled with Dubs . Stories from our past ; videos and pictures of our many adventures over the years together.
    //   He has a lot of content that I don’t have and that’s the great thing about the friends wall.
    //   "
    //   slides={[
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/06ca11f7-0340-4bd9-9a32-8e938102b926.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/7c7b0991-0273-4a1d-990a-7b5b77a846ea.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/9d753220-1a8d-4644-94b3-c8a9441612b2.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/028f2b0c-1248-4778-9f71-02c8172cf44e.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/992bb169-e3c5-4cb0-be62-1ad5c76bebb5.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/a2e980e7-c0da-4c1f-82bc-2ab16d22ec70.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/aef68221-b71c-4123-9fe5-3506e7fdd11a.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/e941e35d-6537-4fd3-b26f-ace3d5e066c3.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/ec0ee17f-609c-40ad-a2a2-7ed3cabbdf80.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/effe70e6-b566-4ac4-b955-331209b6c343.jpg',
    //     },
    //     {
    //       photoUrl:
    //         '/img/galleries/friends/f097d092-e2e5-4554-a961-beeb7d845fbd.jpg',
    //     },
    //     { photoUrl: '/img/galleries/friends/IMG_0332.jpg' },
    //     { photoUrl: '/img/galleries/friends/IMG_1922.jpg' },
    //     { photoUrl: '/img/galleries/friends/IMG_6048.jpg' },
    //   ]}
    // />
  );
}
