import * as React from 'react';
import { StyleSheet, Text, useWindowDimensions } from 'react-native';

import { Color, Dimen } from '../constants';

type Props = {
  typeface: 'h1' | 'h2' | 'h3' | 'button' | 'body1';
  color?: string;
  selectable?: boolean;
  children: React.ReactNode;
};

export default function LegacyText({
  typeface,
  color = Color.white,
  children,
  ...rest
}: Props) {
  const { width: windowWidth } = useWindowDimensions();

  return (
    <Text
      style={[
        typefaceStyles[typeface],
        { color },
        windowWidth <= Dimen.breakpointMaxMobile &&
          typefaceMobileStyles[typeface],
      ]}
      {...rest}
    >
      {children}
    </Text>
  );
}

const typefaceStyles = StyleSheet.create({
  h1: { fontSize: 100, fontFamily: 'GeneralSans-Medium', lineHeight: 110 },
  h2: { fontSize: 34, fontFamily: 'GeneralSans-Regular' },
  h3: { fontSize: 24, fontFamily: 'GeneralSans-Regular' },
  button: { fontSize: 24, fontFamily: 'GeneralSans-Medium' },
  body1: { fontSize: 18, fontFamily: 'GeneralSans-Regular' },
  body2: { fontSize: 16, fontFamily: 'GeneralSans-Regular' },
});

const typefaceMobileStyles = StyleSheet.create({
  h1: { fontSize: 75, lineHeight: 82 },
});
