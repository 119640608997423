import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function SafeScreen({ onDismiss }: Props) {
  return (
    <Slideshow
    onDismiss={onDismiss}
    slides={[{ videoUrl: '/video/Legacy Room (20-sec) Digital Vault Animation v1.0.mp4' }]} title={undefined} description={undefined}    />
    // <Slideshow
    //   onDismiss={onDismiss}
    //   title="The Safe"
    //   description="This is a long term store of digital content currently spread around the internet. The idea is post passing this is a safe long term home for unsorted digital history. If you don’t make a plan all your current content will be deleted after you go."
    //   slides={[]}
    // />
  );
}
