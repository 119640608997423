/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Door_Skirtings.gltf
Created: Wed May 31 08:10:04 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    '/optimizedAssets/Door_Skirtings/Door_Skirtings.gltf'
  );
  const lightMap = useTexture(
    '/optimizedAssets/Door_Skirtings/Door_Skirtings_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Door_Skirtings_mat.lightMap = lightMap;
  materials.Door_Skirtings_mat.lightMapIntensity = 4;
  materials.Door_Skirtings_mat.lightMap.flipY = false;
  materials.Door_Skirtings_mat.envMap = scene.background;
  materials.Door_Skirtings_mat.envMapIntensity = 6;
  materials.Door_Skirtings_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Skirtings.geometry}
        material={materials.Door_Skirtings_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Door_Skirtings/Door_Skirtings.gltf',
  '/optimizedAssets/Door_Skirtings/Door_Skirtings_Lightmap.jpg',
]);
