import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { View } from 'react-native';

import { Dimen } from '../constants';

type PaddingAmount = true | number;

type Props = {
  children: React.ReactNode;
  v?: PaddingAmount;
  h?: PaddingAmount;
  style?: StyleProp<ViewStyle>;
};

export default function PaddedArea({ children, v, h, style }: Props) {
  function toValue(amount: PaddingAmount | undefined): number | undefined {
    if (!amount) return undefined;
    if (amount === true) return Dimen.spacing;
    return Dimen.spacing * amount;
  }
  return (
    <View
      style={[
        { paddingVertical: toValue(v), paddingHorizontal: toValue(h) },
        style,
      ]}
    >
      {children}
    </View>
  );
}
