import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  alignCenter: { alignItems: 'center' },
  alignEnd: { alignItems: 'flex-end' },
  alignStart: { alignItems: 'flex-start' },
  alignStretch: { alignItems: 'stretch' },

  centerBoth: { alignItems: 'center', justifyContent: 'center' },

  fill: { flex: 1 },

  justifyCenter: { justifyContent: 'center' },
  justifyEnd: { justifyContent: 'flex-end' },

  row: { flexDirection: 'row' },

  spaceBetween: { justifyContent: 'space-between' },

  wrap: { flexWrap: 'wrap' },
});
