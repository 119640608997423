// @flow

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Color } from '../../constants';

type Props = {
  size?: number;
  color?: string;
};

export default function IconChevronLeft({
  size = 24,
  color = Color.white,
}: Props) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <Path
        d="M19 2.316L16.895.211 5 12.105 16.895 24 19 21.895l-9.79-9.79L19 2.316z"
        fill={color}
      />
    </Svg>
  );
}
