/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Floor.gltf
Created: Tue May 23 12:52:29 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Floor/Floor.gltf');
  const lightMap = useTexture(
    '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg'
  );
  const { scene } = useThree();

  materials.Floor_mat.lightMap = lightMap;
  materials.Floor_mat.lightMapIntensity = 1;
  materials.Floor_mat.lightMap.flipY = false;
  materials.Floor_mat.envMap = scene.environment;
  materials.Floor_mat.envMapIntensity = 2;
  materials.Floor_mat.aoMap = lightMap;

  materials.Rug_mat.lightMap = lightMap;
  materials.Rug_mat.lightMapIntensity = 1;
  materials.Rug_mat.lightMap.flipY = false;
  materials.Rug_mat.envMap = scene.environment;
  materials.Rug_mat.envMapIntensity = 2;
  materials.Rug_mat.aoMap = lightMap;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.Floor_mat}
      />
      <mesh
        receiveShadow
        geometry={nodes.Rug.geometry}
        material={materials.Rug_mat}
      />
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Floor/Floor.gltf',
  '/optimizedAssets/walls_ceiling_floor_Lightmap.jpg',
]);
