import * as React from 'react';

import Slideshow from '../components/Slideshow';

type Props = {
  onDismiss: () => void;
};

export default function PhotosLifeStoryScreen({ onDismiss }: Props) {
  return (
    <Slideshow
      onDismiss={onDismiss}
      // title="Life Story"
      // description="Work with our professional writers to craft your life story. Our writers will create a 3,000-5,000 word life story to capture your life, following an interview session. After you’ve reviewed and final edits have been completed, the final version will be housed in your Legacy Room under the book titled ‘Life Story’."
      // slides={[{ photoUrl: '/img/galleries/life-story/image0.jpg' }]}
    slides={[{ videoUrl: '/video/Legacy Room (20 -sec) Books Animation v1.0.mp4' }]} title={undefined} description={undefined}
    />
  );
}
