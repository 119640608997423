/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Mac.gltf
Created: Wed May 31 01:19:51 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { Select } from '@react-three/postprocessing';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF('/optimizedAssets/Mac/Mac.gltf');
  const lightMap = useTexture('/optimizedAssets/Mac/Mac_Lightmap.jpg');
  const { scene } = useThree();

  materials.Mac_mat.lightMap = lightMap;
  materials.Mac_mat.lightMapIntensity = 1;
  materials.Mac_mat.lightMap.flipY = false;
  materials.Mac_mat.envMap = scene.background;
  materials.Mac_mat.envMapIntensity = 4;

  return (
    <group dispose={null}>
      <Select enabled={props.hoverTarget === 'computer'}>
        <group {...props} scale={100} userData={{ hoverTarget: 'computer' }}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mac.geometry}
            material={materials.Mac_mat}
          />
        </group>
      </Select>
    </group>
  );
}

useGLTF.preload([
  '/optimizedAssets/Mac/Mac.gltf',
  '/optimizedAssets/Mac/Mac_Lightmap.jpg',
]);
