/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 Suitcase.gltf
Created: Tue May 30 22:46:11 2023
*/

import React, { useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function Model(props) {
  const { nodes, materials } = useGLTF(
    'optimizedAssets/Suitcase/Suitcase.gltf'
  );
  const lightMap = useTexture('optimizedAssets/Suitcase/Suitcase_Lightmap.jpg');
  const { scene } = useThree();

  materials.Suitcase.lightMap = lightMap;
  materials.Suitcase.lightMapIntensity = 1;
  materials.Suitcase.lightMap.flipY = false;
  materials.Suitcase.envMap = scene.background;
  materials.Suitcase.envMapIntensity = 2;

  return (
    <group {...props} dispose={null} scale={100}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Suitcase.geometry}
        material={materials.Suitcase}
      />
    </group>
  );
}

useGLTF.preload([
  'optimizedAssets/Suitcase/Suitcase.gltf',
  'optimizedAssets/Suitcase/Suitcase_Lightmap.jpg',
]);
